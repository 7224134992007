.borderCss {
    border-width: 2px;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    overflow: hidden;
}

.borderCss::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#000000, #141514), linear-gradient(#f34040, #f33434), linear-gradient(#141514, #141514), linear-gradient(#ea2020, #f41919);
    animation: rotate 4s linear infinite;
}

.borderCss::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
}